import { Box, Grid, TableContainer, Typography, Paper, Table, TableBody, styled, TableCell, tableCellClasses, TableRow, TextField, Button } from '@mui/material';
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import { InputUnstyled, TextareaAutosize } from '@mui/base';
import { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './styles.scss'
import GigLogo from '../../../../assests/logos.png'
import { CalendarBox, CustomRow } from '../../../app.styles';
import { Form, Formik } from 'formik';
import { InvoiceSchema } from '../../../../utils/formikValidations';
import { getUserId, getUserType } from '../../../../utils/user';
import { getActiveGigs } from '../../../../services/engineerGig';
import { toast } from 'react-toastify';
import { createUpdateInvoice } from '../../../../services/invoicing';
import { getGigs } from '../../../../services/gig';
import { NumberFormatter, disableEnableBodyScroll } from '../../../../utils/helpers';

function InvoiceModal(props) {
    const { setaddInvoiceModalOpen, addInvoiceModalOpen, handleModalClose, job } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    let options = [ "ADVANCE", "INTERIM", "MILESTONE", "FINAL" ];

    const [startDate, setstartDate] = useState(undefined)
    const [endDate, setendDate] = useState(undefined)
    const [gigList, setgigList] = useState(undefined)
    const [invoiceDescription, setinvoiceDescription] = useState('')

    const [hourlyRate, sethourlyRate] = useState(0)
    const [hoursWorked, sethoursWorked] = useState(0)
    const [invoiceTotal, setinvoiceTotal] = useState(0)
    const [employeeReceive, setemployeeReceive] = useState(0)
    const [selectedInvoiceType, setselectedInvoiceType] = useState(options[0])
    const [selectedGig, setselectedGig] = useState(undefined)
    const [selectedInvoice, setselectedInvoice] = useState(undefined)
    const [selectedCurrency, setselectedCurrency] = useState("$")
    const [isTncActive, setisTncActive] = useState(false)
    const [tncIsChecked, settncIsChecked] = useState(false);
    const [activeInvoiceId, setactiveInvoiceId] = useState(undefined)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 300
        }
      }
    };


    const handleModalFormSubmit = (values) => {
        // console.log(values)
    }
    const userType = getUserType();
    const handleSetData = (data) => {
        if(!!props.activeInvoice){
            setselectedInvoice(props.activeInvoice);
            setselectedGig(props.activeInvoice.gigDetails);
            setactiveInvoiceId(props.activeInvoice._id);
            setselectedInvoiceType(props.activeInvoice.invoiceType);
            setstartDate(new Date(props.activeInvoice.fromDate))
            setendDate(new Date(props.activeInvoice.toDate))
            sethourlyRate(props.activeInvoice.hourlyRate)
            setinvoiceDescription(props.activeInvoice.invoiceDescription)
            sethoursWorked(props.activeInvoice.hoursWorked)
            setselectedCurrency(props.activeInvoice.currency)
        }
        else{
            if(!!job){
                setselectedGig(job);
                sethourlyRate(job.scopeAndBudget.hourlyRate)
                setselectedCurrency(job.scopeAndBudget.currency)
            }
            else{
                setselectedGig(data.gigList[0])
                sethourlyRate(data.gigList[0].scopeAndBudget.hourlyRate)
                setselectedCurrency(data.gigList[0].scopeAndBudget.currency)
            }
        }
        setgigList(data.gigList)
    }
    
    const fetchData = () => {
        const obj = {
            'userId': getUserId(),
            'paginationInput': {
                pageNumber: 1,
                pageSize: 100
            },
            'status': ['ACTIVE']
        };
        if(userType=="business"){
            getGigs(obj, handleSetData);
        }
        else{
            getActiveGigs(obj, handleSetData);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])

    const handleDropdownItemSelect = (e) => {
        setselectedGig(gigList[e.target.selectedIndex])
        sethourlyRate(gigList[e.target.selectedIndex].scopeAndBudget.hourlyRate)
    }

    const handleDropdownCurrencySelect = (e) => {
        setselectedCurrency(e.target.value)
    }

    useEffect(() => {
        // Invoice total = Hourly Rate * Hours Worked
        let tempTotal = hourlyRate*hoursWorked;
        setinvoiceTotal(tempTotal)
        // You’ll receive or final total = Invoice Total - (Invoice Total * GE Fee / 100)
        setemployeeReceive((tempTotal) - ((tempTotal * 10)/100))
    }, [hourlyRate,hoursWorked])

    useEffect(() => {
        disableEnableBodyScroll()
    
      return () => {
        disableEnableBodyScroll()
      }
    }, [])

    const handleSubmitData = () => {
        let data;
        if(!!props.activeInvoice){
            data = {
                "gigId": selectedInvoice.gigId,
                "engineerId": selectedInvoice.engineerId,
                "businessId": selectedInvoice.businessId,
                "currency": selectedCurrency,
                "status": "PENDING",
                "invoiceType": selectedInvoiceType,
                "invoiceDescription": invoiceDescription,
                "hoursWorked": Number(hoursWorked),
                "hourlyRate": Number(hourlyRate),
                "invoiceTotal": invoiceTotal,
                "finalTotal": employeeReceive,
                "invoiceDate": (new Date()).toISOString(),
                "proofPayment": "string",
                "fromDate": startDate.toISOString(),
                "toDate": endDate.toISOString(),
                "rejectReason": "string"
            }
        }
        else{
            data = {
                "gigId": selectedGig["_id"],
                "engineerId": getUserId(),
                "businessId": selectedGig["userId"],
                "currency": selectedCurrency,
                "status": "PENDING",
                "invoiceType": selectedInvoiceType,
                "invoiceDescription": invoiceDescription,
                "hoursWorked": Number(hoursWorked),
                "hourlyRate": Number(hourlyRate),
                "invoiceTotal": invoiceTotal,
                "finalTotal": employeeReceive,
                "invoiceDate": (new Date()).toISOString(),
                "proofPayment": "string",
                "fromDate": startDate.toISOString(),
                "toDate": endDate.toISOString(),
                "rejectReason": "string"
            }
        }
        if(!!activeInvoiceId){
            data._id = activeInvoiceId
        }
        try{
            createUpdateInvoice(data).then((e)=>{
                if(!!activeInvoiceId){
                    toast.success("Invoice Updated Successfully!")
                }
                else{
                    socket.emit('postNotification', {
                        "notificationDescription": `An invoice for ${selectedGig?.titleAndSkills?.projectTitle} has been submitted for payment`,
                        "userRole": ["business"],
                        "businessId": selectedGig["userId"],
                        "isRead": false,
                        "businessProfilePic": selectedGig?.companyDetails?.logo,
                        "redirectUrl": "/business/invoices/pending",
                    });
                    socket.emit('postNotification', {
                        "notificationDescription": `Invoice submitted for ${selectedGig?.titleAndSkills?.projectTitle} to ${selectedGig?.companyDetails?.companyName}`,
                        "userRole": ["admin"],
                        "isRead": false,
                        "redirectUrl": "",
                    });
                    toast.success("Submitted Successfully!")
                }
                if(!!props.activeInvoice){
                    handleModalClose();
                }
                else{
                    setaddInvoiceModalOpen(false);
                }
            }).catch((err)=>{console.error(e)})
        }
        catch(err){
            console.error(err)
        }
    }
    
    

    const handleFormSubmit = () => {
        if(!!selectedGig && !!startDate && !!endDate && !!hourlyRate && !!hoursWorked){
            setisTncActive(true)
            // handleSubmitData()
        }
        else{
            toast.error("Form not filled properly. Please try again!")
        }

    }

    return (
        <div className="modal-wrapper">
            {
                isTncActive?
                <div className="tnc-modal">
                    <span>By clicking on confirm you agree to accept the payment terms as negotiated with the gig owner you have been hired by.</span>
                    <span><label><input type={"checkbox"} checked={tncIsChecked} onChange={()=>{settncIsChecked((prev) => !prev)}} /> Accept Gig Engineer <a href={"/termsconditions"} target={"_blank"}>T&Cs</a></label></span>
                    <div className="cta-wrapper">
                        <Button variant="contained" disabled={!tncIsChecked} className={tncIsChecked?"primary-Main-Btn":"checked-Process-btn"} onClick={()=>{handleSubmitData()}}>Confirm</Button>
                        <Button variant="contained" className="popup-cancel" type="submit" onClick={()=>{setisTncActive(false)}}>Cancel</Button>
                    </div>
                </div>
                :
                <div className="modal-card">
                    <div className="modal-top">
                        <span onClick={()=>{setaddInvoiceModalOpen(false)}}>&times;</span>
                    </div>
                    <div className="modal-header">
                        <img src={GigLogo} />
                        Invoice
                    </div>
                        <CustomRow className='inputs-wrapper' container style={{display:"flex", justifyContent: "space-between", alignItems: 'flex-start', marginTop: "12px"}}>
                            <div className='left'>
                                <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                    Invoice Type:
                                </Box>
                                <select defaultValue={selectedInvoiceType} onChange={(e)=>{setselectedInvoiceType(options[e.target.selectedIndex])}}>
                                    {options.map((item,index)=>(
                                        <option>{item}</option>
                                    ))}
                                </select>
                                {activeInvoiceId && <div className='left-sub'>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        Invoice Id:
                                    </Box>
                                    <input type={"text"} disabled value={!!selectedInvoice&&!!selectedInvoice.invoiceId?selectedInvoice.invoiceId:""} />
                                </div>}
                            </div>
                            <div className='right'>
                                <div className='right-sub'>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        Gig Name:
                                    </Box>
                                    {
                                        activeInvoiceId?
                                        <input disabled type={"text"} value={selectedGig?.titleAndSkills?.projectTitle} />
                                        :
                                        !!job?
                                        <input disabled type={"text"} value={selectedGig?.titleAndSkills?.projectTitle} />
                                        :
                                        <select onChange={(e)=>{handleDropdownItemSelect(e)}}>
                                            {!!gigList && gigList.map((item,index)=>(
                                                <option>{item.titleAndSkills.projectTitle}</option>
                                            ))}
                                        </select>
                                    }
                                </div>
                                <div>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        Invoice Date:
                                    </Box>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        className='disabled-picker'
                                        views={['year', 'month', 'day']}
                                        value={new Date()}
                                        name="invoicedDate"
                                        inputFormat="dd/MM/yyyy"
                                        disabled
                                        onChange={(event) => {
                                            return;
                                        }}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <CalendarBox style={{ border: '1px solid rgba(0, 0, 0, 0.23)', marginTop: '4px' }}>
                                            {InputProps?.endAdornment}
                                            <InputUnstyled className="calendar-Input" ref={inputRef} {...inputProps} />
                                            </CalendarBox>
                                        )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        Start Date:
                                    </Box>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        style={{background:"red"}}
                                        views={['year', 'month', 'day']}
                                        value={new Date(startDate || '')}
                                        name="startDateAfter"
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newValue) => {
                                            setstartDate(newValue);
                                        }}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <CalendarBox style={{ border: '1px solid rgba(0, 0, 0, 0.23)', marginTop: '4px', zIndex: 13000 }}>
                                            {InputProps?.endAdornment}
                                            <InputUnstyled className="calendar-Input" ref={inputRef} {...inputProps} />
                                            </CalendarBox>
                                        )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        End Date:
                                    </Box>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        views={['year', 'month', 'day']}
                                        value={new Date(endDate || '')}
                                        name="endDateAfter"
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newValue) => {
                                            setendDate(newValue);
                                        }}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <CalendarBox style={{ border: '1px solid rgba(0, 0, 0, 0.23)', marginTop: '4px' }}>
                                            {InputProps?.endAdornment}
                                            <InputUnstyled className="calendar-Input" ref={inputRef} {...inputProps} />
                                            </CalendarBox>
                                        )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </CustomRow>
                        <CustomRow className='inputs-wrapper' container style={{display:"flex", justifyContent: "space-between", alignItems: 'flex-start', marginTop: "12px"}}>
                            <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                Invoice Description:
                            </Box>
                            <textarea maxLength={500} placeholder={'Max 500 characters'} onChange={(e)=>{setinvoiceDescription(e.target.value)}} value={invoiceDescription}/>
                        </CustomRow>
                        <CustomRow className='inputs-wrapper' container style={{display:"flex", justifyContent: "space-between", alignItems: 'flex-start', marginTop: "12px"}}>
                            <div className='inputs-calc-wrapper rate-wrapper'>
                                <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                    Hourly Rate:
                                </Box>
                                <span>
                                    <select value={selectedCurrency} onChange={(e)=>{handleDropdownCurrencySelect(e)}}>
                                        <option>$</option>
                                        <option>R</option>
                                    </select>
                                    <div className='hour-rate-wrapper'>
                                        <label className='hour-rate' htmlFor='hour-rate'>{NumberFormatter(hourlyRate)}</label>
                                        <input id={"hour-rate"} type={'text'} min={0} value={hourlyRate} onChange={(e)=>{sethourlyRate(e.target.value)}} />
                                    </div>
                                </span>
                            </div>
                            <div className='inputs-calc-wrapper'>
                                <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                    Hours Worked:
                                </Box>
                                <input type={'text'} min={0} value={hoursWorked} onChange={(e)=>{sethoursWorked(e.target.value)}} />
                            </div>
                        </CustomRow>
                        <hr className='separator' />
                        <CustomRow className='inputs-wrapper' container style={{display:"flex", justifyContent: "flex-end", alignItems: 'flex-start', marginTop: "12px"}}>
                            <div className='inputs-calc-wrapper right-aligned'>
                                <div>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        Invoice Total:
                                    </Box>
                                    <input disabled style={{userSelect:"none", cursor: "not-allowed"}} type={'text'} min={0} value={NumberFormatter(invoiceTotal)} />
                                </div>
                                <span>Total amount the client will see on your invoice</span>
                            </div>
                        </CustomRow>
                        <hr className='separator' />
                        <CustomRow className='inputs-wrapper' container style={{display:"flex", justifyContent: "flex-end", alignItems: 'flex-start', marginTop: "12px"}}>
                            <div className='inputs-calc-wrapper right-aligned'>
                                <b>Gig Engineer Service Fee : 10%</b>
                            </div>
                        </CustomRow>
                        <hr className='separator' />
                        <CustomRow className='inputs-wrapper' container style={{display:"flex", justifyContent: "flex-end", alignItems: 'flex-start', marginTop: "12px"}}>
                            <div className='inputs-calc-wrapper right-aligned'>
                                <div>
                                    <Box sx={{ marginBottom: '5px', color: 'primary.main', marginTop: '4px' }}>
                                        You'll receive:
                                    </Box>
                                    <input disabled style={{userSelect:"none", cursor: "not-allowed"}} type={'text'} min={0} value={NumberFormatter(employeeReceive)} />
                                </div>
                                <span>The Estimated amount you'll receive after service fees</span>
                            </div>
                        </CustomRow>
                        <hr className='separator' />
                        <div className='submit-cta' onClick={()=>{handleFormSubmit()}}>Submit</div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      gigs: state.gigs
    };
};

export default connect(mapStateToProps)(InvoiceModal)
