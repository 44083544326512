import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { TypographyBold, PaddedBox, CustomRow, PaddedFooter } from '../../app.styles';
import Checkbox from '@mui/material/Checkbox';
import {
  setProfileError,
  setProfileSuccess,
  resetProfileSuccess
} from '../../../store/actions/profile';
import { Grid, Button, Link, Dialog, Typography, Rating, TextField, IconButton } from '@mui/material';
import { getQueryParams } from '../../../utils/helpers';
import { cancelFeedBackApi, feedBackApiApplicant, getGigApplicants, hireApplicant } from '../../../services/gig';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import GridTableEngineer from '../../../components/GridTable/GridTableEngineer';
import DialogTitle from '@mui/material/DialogTitle';
import { setCookie } from '../../../utils/cookie';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../../../components/footer1/footer';
import ProposalIcon from "../../../assests/ProposalIcon.svg"
import './styles.scss'
import ApplicantTable from '../../../components/GridTable/ApplicantTable';

const Applicants = (props) => {

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const query = getQueryParams();
  const queryPage = query.page || 1; // active gig page
  const queryApplicantPage = query.applicantPage || 1; // active gig page
  const gigName = query.gigname;
  const [rows, setRows] = useState([]);
  const [applicantPage, setApplicantPage] = useState(queryApplicantPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [dataForHire, setDataForHire] = React.useState({});
  const [checked, setChecked] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [rating, setRating] = useState(1);
  const [ratingError, setRatingError] = useState('');

  const handleCloseforComplete = () => {
    setOpenComplete(false);
    cancelMethod();
  };

  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const FeedbackMethod = async () => {
    let feedback = document.getElementById("standard-search").value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: "afterHire"
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.')
    }

    feedBackApiApplicant(data).then((e)=>{
      setOpenComplete(false);
      toast.success("Success");
      setCookie('setOpenComplete', false, 30);
    })
  };

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: "afterHire"
    };

    cancelFeedBackApi(data).then((e)=>{
      setOpenComplete(false);
    })
  };

  const handleCheck = () => {
    setChecked(!checked);
  }

  const history = useHistory();
  const { id } = useParams();
  const SubmitHire = async (e) => {
    e.preventDefault();
    let location = props.newGig.objLocation;
    let data = {
      gigId: dataForHire.gigId,
      userId: dataForHire.userId,
      engineerName: dataForHire.engineerName,
      gigName: gigName,
      location: {
        country: location.country ? location.country : '',
        state: location.state ? location.state : location.province,
        city: location.city ? location.city : '',
      },
      fromDate: props.newGig.startDate ? props.newGig.startDate : '',
      // endDate: '',
    }
    
    hireApplicant(data).then((e)=>{
      socket.emit('postNotification', {
        "notificationDescription": `Congratulations, you've been hired for ${gigName}`,
        "userRole": ["engineer"],
        "engineerId": dataForHire.userId,
        "isRead": false,
        "engineerProfilePic": dataForHire.profilePic,
        "redirectUrl": "/active",
      });
      socket.emit('postNotification', {
        "notificationDescription": `${dataForHire.engineerName} has been hired for ${gigName} by ${props.profile.basicInfo.name}`,
        "userRole": ["admin"],
        "isRead": false,
        "redirectUrl": "",
        "engineerProfilePic": dataForHire.profilePic
      })
      setConfirmOpen(false);
      setSuccessOpen(true);
    })
  }

  const getMessageLink = (msglink, userId, gigId, engineerName, status, attachment, coverLetter, profilePic) => {

    return (
      <div>
        <Link
          className="message-Link"
          component="button"
          variant="body2"
          onClick={() => {
            history.push(msglink);
          }}
        >
          Message <span>&gt;</span>
        </Link>
        <br />
        <div className='business-table-cta-wrapper'>
          <Button
            variant="contained"
            className="popup-process"
            sx={{
              textTransform: 'uppercase',
              alignItems: 'center'
            }}
            onClick={() => {
              setConfirmOpen(true);
              setDataForHire({ ...dataForHire, ...{ userId, gigId, engineerName, profilePic } });
            }}
          >
            Hire
          </Button>
          {
            (!!attachment || !!coverLetter)
            &&
            <img
              onClick={() => {
                history.push(`/gig/active/proposal/${gigId}`, {
                  attachment: attachment,
                  coverLetter: coverLetter,
                })
              }}
              className='proposal-business-cta' src={ProposalIcon}
            />
          }
        </div>
      </div>
    )
  }

  const headers = ['APPLICANT NAME', 'LOCATION', 'AVAILABILITY', 'RATE', 'EMAIL', 'ACTIONS'];

  function createData(name, country, availability, rate, emailId, message, userId, gigId, engineerName, status) {
    return [name, country, availability, rate, emailId, getMessageLink(message, userId, gigId, engineerName, status), userId];
  }

  const location = useLocation();

  const setData = (data) => {
    const rowData = [];
    const gigList = _get(data, 'engineerList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    for (let i = 0; i < gigList.length; i++) {
      const gig = gigList[i].userId.profileData;
      const attachment = gigList[i].attachment;
      const coverLetter = gigList[i].coverLetter;
      const name = `${_get(gig, 'basicInfo.title', '')} ${_get(gig, 'basicInfo.name', '')}`;
      const country = _get(gig, 'basicInfo.country', 'Not Disclose');
      const availability = _get(gig, 'myProfile.availabilityHoursPerWeek', '') ? `${_get(gig, 'myProfile.availabilityHoursPerWeek', '')}
       hrs/week` : '' ? _get(gig, 'myProfile.availabilityHoursPerWeek', '') ? `${_get(gig, 'myProfile.availabilityHoursPerWeek', '')} hrs/week` : '' : 'Not Disclosed';
      const rate = _get(gig, 'myProfile.hourlyRate', '') ? `${_get(gig, 'myProfile.currency', 'R')} ${_get(gig, 'myProfile.hourlyRate', '')} /hr` : '' ?
        _get(gig, 'myProfile.hourlyRate', '') ? `${_get(gig, 'myProfile.currency', 'R')} ${_get(gig, 'myProfile.hourlyRate', '')} /hr` : '' : 'Not Disclosed';
      const emailId = _get(gig, 'basicInfo.emailId', '');
      const engineerName = _get(gig, 'basicInfo.name', '');
      const userId = _get(gig, 'basicInfo.userId', '');
      const gigId = !!props.newGig.id ? props.newGig.id : gigList[i].gigId;
      const status = _get(gig, 'basicInfo.status', '');
      const profilePic = _get(gig, 'myProfile.profilePic');
      const message = `/ochat?to=${_get(gig, 'basicInfo.userId', '')}`
      rowData.push([name, country, availability, rate, emailId, getMessageLink(message, userId, gigId, engineerName, status, attachment, coverLetter, profilePic), userId, gigId, gig, engineerName, status, userId]);
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 10
      },
      gigId: id
    };
    getGigApplicants(obj, setData);
  };

  useEffect(() => {
    fetchData(applicantPage);
  }, []);

  const handleChange = (event, value) => {
    fetchData(value);
    setApplicantPage(value);
  };

  const handleRClick = (data) => {
    const engineerId = _last(data);
    let redirectUrl;
    {
      (location.pathname.includes("active") && (
        redirectUrl = `/gig/active/applicants/${id}?page=${queryPage}&applicantPage=${applicantPage}&gigname=${gigName}`
      )
      )
    }
    {
      (location.pathname.includes("inact") && (
        redirectUrl = `/gig/inact/applicants/${id}?page=${queryPage}&applicantPage=${applicantPage}&gigname=${gigName}`
      )
      )
    }
    {
      (location.pathname.includes("closed") && (
        redirectUrl = `/gig/closed/applicants/${id}?page=${queryPage}&applicantPage=${applicantPage}&gigname=${gigName}`
      )
      )
    }
    history.push(`/engineer/details/${engineerId}?redirectUrl=${encodeURIComponent(redirectUrl)}`);
  };

  const goBack = (e) => {
    {
      (location.pathname.includes("active") && (
        history.push(`/gig/active`)
      )
      )
    }
    {
      (location.pathname.includes("inact") && (
        history.push(`/gig/inactive`)
      )
      )
    }
    {
      (location.pathname.includes("closed") && (
        history.push(`/gig/closed`)
      )
      )
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  function getApplicantHeader() {
    return (
      <>
        <Button
          variant="outlined"
          className="h-color-button-outlined smallButton"
          onClick={() => {
            goBack();
          }}
        >
          {' '}
          Back{' '}
        </Button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        {`List of Applicants for ${gigName} Gig`}
      </>
    );
  }

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }} >
        <Grid container>
          <CustomRow container className="bottom-Margin">
            <TypographyBold variant="h6" component="h6">
              {getApplicantHeader()}
            </TypographyBold>
          </CustomRow>
          <Grid container className="table-Padding">
            <ApplicantTable
              headers={headers}
              rows={rows}
              handleRClick={handleRClick}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(applicantPage)}
              emptyMessage="No one has applied for the Gig yet."
            ></ApplicantTable>
          </Grid>
        </Grid>
        <Dialog open={confirmOpen} onClose={handleClose}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', }}
          >
            <br />
            <Typography
              sx={{ textAlign: 'center' }}
            >
              By clicking confirm you agree to hire this applicant as per the terms of engagement.
              If you would like to attach any further company specific contractual terms and conditions you may do <br /> so via the chat function.
            </Typography>
            <Typography
              sx={{ marginTop: '30px' }}
            >
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="check"
                name="checkbox"
                checked={checked}
                onClick={handleCheck}
              />
              Accept Gig Engineer <a href="https://gigengineer.io/termsconditions">T&amp;Cs</a>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                marginTop: '50px',
              }}
            >
              <Button
                variant="contained"
                className="checked-Process-btn"
                
                disabled={!checked}
                sx={{
                  width: "140px",
                  backgroundColor: '#0140AA',
                }}
                onClick={SubmitHire}
              >
                Proceed
              </Button>
              <Box
                sx={{
                  marginLeft: '20px',
                }}                        >
                <Button
                  variant="contained"
                  className="popup-cancel"
                  type="submit" sx={{
                    width: '140px',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
            <br />
          </Box>
        </Dialog>

        {/* success dialog */}
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={successOpen} onClose={handleClose}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
          >
            <br />
            <Typography
              sx={{ textAlign: 'center' }}
            >
              Congratulations you have successfully hired <strong>{dataForHire.engineerName}</strong> for <strong>{gigName}</strong>!
            </Typography>
            <Box
              sx={{
                marginTop: '20px'
              }}                        >
              <Button
                variant="contained"
                className="popup-cancel"
                type="submit" sx={{
                  width: '120px',
                }}
                onClick={() => {
                  setSuccessOpen(false);
                  setOpenComplete(true);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* feedback success */}
        <Dialog open={openComplete} onClose={handleCloseforComplete}>
          <Box
            sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseforComplete}>
            </BootstrapDialogTitle>
            <h2>Hey there!</h2>
            <Typography>
              Please provide feedback on your hiring experience.
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginTop: "12px" }}>
                <Rating
                  name="size-large"
                  defaultValue={rating}
                  size="large"
                  onChange={(event) => {
                    let starValue = event.target.value;
                    if (rating != parseInt(starValue)) {
                      setRating(parseInt(starValue));
                      setRatingError(null)
                    }
                    if (rating === parseInt(starValue)) {
                      setRating(0)
                      setRatingError('Please provide the rating.')
                    }
                  }}

                />
              </Typography>
              <Box
                sx={{ marginTop: "12px", marginLeft: "20px", fontSize: "20px" }}
              >
                ({rating}/5)
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '12px',
                color: 'red'
              }}
            >
              {ratingError}
            </Typography>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "60ch" },
                marginTop: "-10px",
              }}
              autoComplete="off"
            >
              <div>
                <TextField
                  id="standard-search"
                  label="Feedback (Optional)"
                  // value={feedback}
                  type="search"
                  variant="standard"
                />
              </div>
            </Box>
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <Button
                variant="contained"
                className="popup-process"
                type="submit"
                sx={{
                  width: "120px",
                }}
                onClick={FeedbackMethod}
              >
                Submit
              </Button>
              <br />
            </Box>
          </Box>
        </Dialog>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applied: state.applied || {},
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    newGig: state.newGig,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    setNewGigDetails: (data) => dispatch(setNewGigDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);

