import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, EditedCard, PaddedFooter, NolongerAppliedContainer } from '../../app.styles';
import {
  Grid,
  CardContent,
  Avatar,
  Typography,
  Box,
  Link,
  Button
} from '@mui/material';
import { getAppliedGigs } from '../../../services/engineerGig';
import Pagination from '@mui/material/Pagination';
import { getGigLocation, scrollOnTop, formatDate, getDurationLabel } from '../../../utils/helpers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { connect } from 'react-redux';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import DetailsIcon from '@mui/icons-material/Details';
import { getUserType } from '../../../utils/user';
import GridViewIcon from '@mui/icons-material/GridView';
import './Applied.css';
import Footer from '../../../components/footer1/footer';
import logo from "../../../assests/TableView.svg"
import ProposalIcon from "../../../assests/ProposalIcon.svg"
import GridTableEngineer from '../../../components/GridTable/GridTableEngineer';
import Hours from '../../../assests/Hours.svg';
import Days from '../../../assests/Days.svg';
import Weeks from '../../../assests/Weeks.svg';
import Months from '../../../assests/Months.svg';
import Skills from '../../../assests/Skills.png';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PageLoader from '../../../components/Loaders/PageLoader';
const getSkillsList = (skills) => {
  return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
};

const Applied = (props) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const filterApplied =
    !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);

  const [count, setCount] = useState(1);
  const [engineerProfile, setEngineerProfile] = useState([]);
  const [paginationVal, setPaginationVal] = useState(1);
  const [tableData, setTableData] = useState('tableView');
  const history = useHistory();
  const userType = getUserType(); // get the user type from tokens

  const setListData = (data) => {
    setList(_get(data, 'gigList', []));
    setPage(_get(data, 'paginationResult.currentPage', {}) || 1);
    setCount(_get(data, 'paginationResult.totalPages', {}) || 1);
  };

  const getMessageLink = (id, obj) => {
    return (
      <div>
        {userType === 'engineer' && (
          <Box
            className="action-wrapper"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Button
              className="admin-all-btn"
              sx={{
                color: '#FFFFFF',
                borderRadius: '5px',
                width: '127px',
                height: '32px',
                textTransform: 'capitalize'
              }}
              onClick={() => {
                history.push(`/applied/${_get(obj, '_id', '')}`, obj);
              }}
            >
              View Details
            </Button>
            {(!!obj.applicationDetails.attachment || !!obj.applicationDetails.coverLetter) && (
              <img
                onClick={() => {
                  history.push(`/applied/proposal/${obj['_id']}`, {
                    attachment: obj.applicationDetails.attachment,
                    coverLetter: obj.applicationDetails.coverLetter
                  });
                }}
                className="proposal-cta"
                src={ProposalIcon}
              />
            )}
          </Box>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (tableData === 'cardView') {
      fetchData(filterApplied ? 1 : page);
    } else {
      fetchData(filterApplied ? 1 : paginationVal);
    }
  }, [props.searchKey, props.filters, props.sortBy]);

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      },
      filter: {
        gigStatusList: ['APPLIED'],
        profileCompletionPercent: 60
      },
      ...props.sortBy
    };
    if (userType === 'engineer') {
      obj.filter.gigStatusList.push('INACTIVE');
      obj.filter.gigStatusList.push('CLOSED');
    }

    if (!_isEmpty(props.searchKey)) {
      obj.search = props.searchKey;
    }

    if (!_isEmpty(props.filters)) {
      obj.filter = {
        ...obj.filter,
        ...props.filters
      };
    }

    getAppliedGigs(obj, setListData, setListDataEngineer);
  };

  const setListDataEngineer = (data) => {
    const rowData = [];
    const engineerListData = _get(data, 'gigList', []);
    const totalRecords = _get(data, 'paginationResult', {});
    if (engineerListData && engineerListData.length > 0) {
      for (let obj of engineerListData) {
        const gigName = _get(obj, 'titleAndSkills.projectTitle', '')
          ? _get(obj, 'titleAndSkills.projectTitle', '')
          : '-';
        const postOn = formatDate(_get(obj, 'createdAt', '-') ? _get(obj, 'createdAt', '-') : '-');
        const gigId = _get(obj, 'applicationDetails.gigId', '');
        const location = _get(obj, 'deliverablesAndLocation.location.country', '-')
          ? _get(obj, 'deliverablesAndLocation.location.country', '-')
          : '-';
        const postBy = _get(obj, 'companyDetails.companyName', '')
          ? _get(obj, 'companyDetails.companyName', '')
          : '-';
        const userId = _get(obj, 'userId', '');
        const id = _get(obj, '_id', '');
        const attachment = _get(obj, 'applicationDetails.attachment', '');
        const coverLetter = _get(obj, 'applicationDetails.coverLetter', '');
        rowData.push([
          gigName,
          postOn,
          location,
          postBy,
          getMessageLink(id, obj),
          obj,
          gigId,
          userId,
          attachment,
          coverLetter
        ]);
      }
    }
    setEngineerProfile(rowData);
    setPaginationVal(totalRecords.totalPages || 1);
  };

  const handleRClick = (obj, data) => {
    history.push(`/applied/${_get(obj[5], '_id', '')}`, obj[5]);
  };

  useEffect(() => {
    fetchData(filterApplied ? 1 : page);
  }, [props.searchKey, props.filters, props.sortBy]);

  const handleChange = (event, value) => {
    scrollOnTop();
    fetchData(value);
    setPage(value);
  };
  const onTableChange = (event, displayView) => {
    fetchData(1);
    if (displayView === 'cardView') {
      setTableData('cardView');
      setPage(1);
    } else {
      setTableData('tableView');
      setPaginationVal(1);
    }
  };

  const headers = ['Gig Name', 'Posted on', 'Location', 'Posted By', 'Action'];

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`,
          minHeight: '580px'
        }}
      >
        <div className="applied-gig-text">
          <Typography className="applied-heading" variant="h6" component="h6" color="pColor.main">
            Applied Gigs
          </Typography>
          {tableData === 'tableView' ? (
            <Box sx={{ cursor: 'pointer', marginTop: '11px' }}>
              <img
                sx={{ color: '#0000FF' }}
                src={logo}
                alt="logotable"
                fontSize="small"
                onClick={(e) => onTableChange(e, 'cardView')}
              />
            </Box>
          ) : (
            <GridViewIcon
              onClick={(e) => onTableChange(e, 'tableView')}
              sx={{ fontSize: '35px', color: '#818181', cursor: 'pointer', marginTop: '6px' }}
            />
          )}
        </div>

        {_isEmpty(list) && filterApplied && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="h-margin-top-1 h-padding-top-1"
          >
            You have not applied to any gigs yet.
          </Grid>
        )}

        {tableData === 'tableView' ? (
          <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
      {_isEmpty(list) && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="no-record-text-table"
          >
            You don’t have any Applied Gigs
          </Grid>
        )}
            {list.map((job, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <EditedCard sx={{ minHeight: '510px' }}>
                  <Box sx={{ cursor: 'pointer', margin: 'auto' }}>
                    <Link
                      onClick={() => {
                        history.push(`/applied/${_get(job, '_id', '')}`, job);
                      }}
                    >
                      <Avatar
                        src={_get(job, 'companyDetails.logo', '')}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: 'cover',
                          margin: 'auto',
                          border: '3px solid #eee',
                          boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                          backgroundColor: '#CCD8EE',
                          marginTop: '0px'
                        }}
                      >
                        {_get(job, 'companyDetails.companyName[0]', '')}
                      </Avatar>
                    </Link>
                  </Box>
                  <div className="project-title-applied-gig" title="Project Title">
                    {_get(job, 'titleAndSkills.projectTitle', '')
                      ? _get(job, 'titleAndSkills.projectTitle', '')
                      : '-'}
                  </div>
                  <CardContent className="card-Content">
                    <Box sx={{ cursor: 'pointer', textAlign: 'center' }}>
                      <Link
                        onClick={() => {
                          history.push(`/applied/${_get(job, '_id', '')}`, job);
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="h6"
                          color="pColor.main"
                          sx={{ fontSize: '16px', marginTop: '-10px', marginLeft: '10px' }}
                        >
                          More Details
                        </Typography>
                      </Link>
                    </Box>
                    {(!!job.applicationDetails.attachment ||
                      !!job.applicationDetails.coverLetter) && (
                      <Link
                        sx={{ marginLeft: '15px' }}
                        className="proposal-card-cta"
                        onClick={() => {
                          history.push(`/applied/proposal/${job['_id']}`, {
                            attachment: job.applicationDetails.attachment,
                            coverLetter: job.applicationDetails.coverLetter
                          });
                        }}
                      >
                        View Proposal <img src={ProposalIcon} />
                      </Link>
                    )}
                    <List>
                      <ListItem disablePadding sx={{ cursor: 'default' }} title="Skills">
                        <ListItemIcon
                          sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '5px' }}
                        >
                          <span enterTouchDelay={0}>
                            <img sx={{ color: '#0000FF' }} src={Skills} alt="skills" width="19px" />
                            {/* <SettingsApplicationsOutlinedIcon
                              sx={{ fontSize: '18px', color: '#000' }}
                            /> */}
                          </span>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                overflow: 'hidden'
                              }}
                            >
                              {getSkillsList(_get(job, 'titleAndSkills.skillsRequired', []))}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        disablePadding
                        sx={{ marginTop: '8px', cursor: 'default' }}
                        title="Location"
                      >
                        <ListItemIcon
                          sx={{ minWidth: '25px', marginLeft: '-1px', marginTop: '5px' }}
                        >
                          <span enterTouchDelay={0}>
                            <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                          </span>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                overflow: 'hidden',
                                marginLeft: '2px'
                              }}
                            >
                              {getGigLocation(job.deliverablesAndLocation, job.isRemote)}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <ListItem
                        disablePadding
                        sx={{ marginTop: '8px', cursor: 'default' }}
                        title="Duration"
                      >
                        <ListItemIcon
                          sx={{ minWidth: '25px', marginLeft: '2px', marginTop: '5px' }}
                        >
                          <span enterTouchDelay={0}>
                            {_get(job, 'scopeAndBudget.durationUnit') === 'PERHOUR' ? (
                              <img
                                sx={{ color: '#0000FF' }}
                                src={Hours}
                                alt="hours"
                                fontSize="medium"
                              />
                            ) : _get(job, 'scopeAndBudget.durationUnit') === 'PERDAY' ? (
                              <img
                                sx={{ color: '#0000FF' }}
                                src={Days}
                                alt="days"
                                fontSize="medium"
                              />
                            ) : _get(job, 'scopeAndBudget.durationUnit') === 'PERWEEK' ? (
                              <img
                                sx={{ color: '#0000FF' }}
                                src={Weeks}
                                alt="weeks"
                                fontSize="medium"
                              />
                            ) : _get(job, 'scopeAndBudget.durationUnit') === 'PERMONTH' ? (
                              <img
                                sx={{ color: '#0000FF' }}
                                src={Months}
                                alt="months"
                                fontSize="medium"
                              />
                            ) : (
                              <img
                                sx={{ color: '#0000FF' }}
                                src={Hours}
                                alt="hours"
                                fontSize="medium"
                              />
                            )}
                          </span>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                              }}
                            >
                              {_get(job, 'scopeAndBudget.projectDuration', '-')}{' '}
                              {getDurationLabel(_get(job, 'scopeAndBudget.durationUnit', 'Hours'))}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        disablePadding
                        sx={{ marginTop: '8px', cursor: 'default' }}
                        title="Rate"
                      >
                        <ListItemIcon
                          sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '5px' }}
                        >
                          <span enterTouchDelay={0}>
                            <AccessTimeIcon sx={{ fontSize: '20px', color: '#000' }} />
                          </span>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                              }}
                            >
                              {_get(job, 'scopeAndBudget.hourlyRate')
                                ? ` ${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(
                                    job,
                                    'scopeAndBudget.hourlyRate'
                                  )}/hr `
                                : 'Not Disclosed'}
                            </Typography>
                          }
                        />
                      </ListItem>

                      {userType === 'engineer'
                        ? null
                        : _get(job, 'hireCount') === 0 && (
                            <ListItem
                              disablePadding
                              sx={{ marginTop: '8px', cursor: 'default' }}
                              title="Project Details"
                            >
                              <ListItemIcon sx={{ minWidth: '25px', marginLeft: '15px' }}>
                                <span enterTouchDelay={0}>
                                  <DetailsIcon sx={{ fontSize: '18px', color: '#000' }} />
                                </span>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      color: '#5E5E5E',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    {_get(job, 'titleAndSkills.projectDetails')
                                      ? ` ${_get(job, 'titleAndSkills.projectDetails')} `
                                      : 'Not Disclosed'}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )}

                      {userType === 'engineer'
                        ? null
                        : _get(job, 'hireCount') === 0 && (
                            <ListItem
                              disablePadding
                              sx={{ marginTop: '8px', marginBottom: '-8px', cursor: 'default' }}
                              title="Project Title"
                            >
                              <ListItemIcon sx={{ minWidth: '25px', marginLeft: '15px' }}>
                                <span enterTouchDelay={0}>
                                  <SubtitlesIcon sx={{ fontSize: '18px', color: '#000' }} />
                                </span>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      color: '#5E5E5E',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    {_get(job, 'titleAndSkills.projectTitle')
                                      ? ` ${_get(job, 'titleAndSkills.projectTitle')} `
                                      : 'Not Disclosed'}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )}
                    </List>
                    <div className="icon-text-appled-gig">
                      {_get(job, 'hireCount') >= 1 && (
                        <NolongerAppliedContainer>
                          <div className="all-contents-text">
                            <ReportGmailerrorredIcon
                              sx={{ fontSize: '25px', color: 'red', marginLeft: '2px' }}
                            />
                            <div className="longer-text">
                              No longer accepting applications for this gig
                            </div>
                          </div>
                        </NolongerAppliedContainer>
                      )}
                    </div>
                  </CardContent>
                </EditedCard>
              </Grid>
            ))}
            {!_isEmpty(list) && (
              <Box sx={{ marginTop: '30px', width: '100%', marginLeft: '35px' }}>
                <Pagination
                  page={page}
                  count={count}
                  shape="rounded"
                  className="pagination-root"
                  onChange={handleChange}
                  defaultPage={1}
                />
              </Box>
            )}
          </Grid>
        ) : (
          <Grid container className="table-Padding" mt={2}>
            <GridTableEngineer
              headers={headers}
              rows={engineerProfile}
              handleChange={handleChange}
              handleRClick={handleRClick}
              pagination={paginationVal}
              defaultPage={_toNumber(paginationVal)}
              emptyMessage="You don’t have any Applied Gigs"
            ></GridTableEngineer>
          </Grid>
        )}
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '200px'
              : '0px'
          }`
        }}
      >
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    searchKey: state.sfs.searchKey,
    filters: state.sfs.filters,
    sortBy: state.sfs.sortBy,
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess,
    representative: state.profile.basicInfo || {},
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSFS: (details) => dispatch(resetSFS(details)),
    setNewGigStep: (details) => dispatch(setNewGigStep(details)),
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details)),
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Applied);
