import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  Typography,
  Input
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { blue } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';
import _toNumber from 'lodash/toNumber';
import { Formik, Form } from 'formik';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { newGigProjectDurationSchema } from '../../../../utils/formikValidations';
import FormTextField from '../../../../components/util/FormTextField';
import { setNewGigStep, setNewGigSuccess, setNewGigError } from '../../../../store/actions/newGig';
import { saveNewDraftGigApi } from '../../../../services/gig';
import { ButtonContainerStep2, CalendarBox, DraftBtnStep2 } from '../../../app.styles';
import StepHeader from './StepHeader';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Step2 from '../../../../assests/Step2.png';
import hours from '../../../../assests/hours.png';
import days from '../../../../assests/days.jpg';
import weeks from '../../../../assests/week.png';
import months from '../../../../assests/month.png';
import './Step3.css';
import './Step2.scss';
import { getDurationLabel, scrollOnTop } from '../../../../utils/helpers';
import { PaddedBox } from '../../../app.styles';

const numberFormatCustom = React.forwardRef(function numberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

numberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const Step = (props) => {
  const scopeAndBudget = _get(props, 'newGig.scopeAndBudget', props.defaultObject.scopeAndBudget);
  const [currency, setCurrency] = useState(scopeAndBudget.currency || 'R');
  const [durationLabel, setdurationLabel] = useState('Hours');
  const hoursbydefaulf = props.newGig?.scopeAndBudget?.durationUnit;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };

  useEffect(() => {
    scrollOnTop();
    props.changeHeadTitle('Please define the scope of work.');
  }, [props]);

  useEffect(() => {
    setdurationLabel(
      props.newGig?.scopeAndBudget?.durationUnit === 'PERHOUR'
        ? 'Hours'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERDAY'
        ? 'Days'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERWEEK'
        ? 'Weeks'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERMONTH'
        ? 'Months'
        : 'Hours'
    );
  }, []);

  const saveToDraft = ({
    values,
    validateForm,
    setTouched,
    newGig,
    setNewGigError,
    setNewGigSuccess
  }) => {
    validateForm().then((errors) => {
      if (_isEmpty(errors)) {
        // verify if errors object is equals to '{}' an empty object
        const obj = {
          ...newGig,
          scopeAndBudget: {
            ...values,
            projectDurationInDays: _toNumber(values.projectDuration),
            durationUnit: values.durationUnit
          },
          status: 'DRAFT'
        };
        saveNewDraftGigApi(obj, setNewGigError, setNewGigSuccess);
      } else {
        return setTouched(errors);
      }
    });
  };

  const activeBackBtn = () => {
    props.methodBackClick(1);
  };

  useEffect(() => {
    setdurationLabel(
      props.newGig?.scopeAndBudget?.durationUnit === 'PERHOUR'
        ? 'Hours'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERDAY'
        ? 'Days'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERWEEK'
        ? 'Weeks'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERMONTH'
        ? 'Months'
        : 'Hours'
    );
  }, []);

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '0px'
              : '0px'
          }`,
          marginTop: '-5px'
        }}
      >
        <div className="step2-main-heading">
          <div className="step2-heading-body">2/3 </div>
          Scope & Budget
        </div>
        <div className="step2-body">
          <div className="step2-img-container">
            <div className="scope-text">Please define the scope of work</div>
            <Typography
              sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '500', color: '#7E84A3' }}
            >
              You can save changes at each step and continue from where you left off.
            </Typography>
            <div className="img-step2-profile">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Post+a+Gig+Step+2.svg"
                alt="profile"
                style={{ width: '350px', height: '350px' }}
              />
            </div>
          </div>

          <div className="Formrik-Container-step2">
            <Formik
              initialValues={{
                ...scopeAndBudget,
                durationUnit: hoursbydefaulf ? hoursbydefaulf : 'PERHOUR'
              }}
              enableReinitialize={true}
              validationSchema={newGigProjectDurationSchema}
              onSubmit={(values) => {
                const obj = {
                  scopeAndBudget: {
                    ...values,
                    projectDurationInDays: _toNumber(values.projectDuration),
                    durationUnit: values.durationUnit
                  }
                };
                props.setNewGigStep(obj);
              }}
            >
              {(formProps) => {
                const { values, setFieldValue, touched, errors, handleBlur } = formProps;
                return (
                  <Form noValidate autoComplete="off">
                    <div className="all-item-step2">
                      <div className="main-container-url-2">
                        <p class="label-2">Project duration {`(${durationLabel})`}</p>
                        <span class="input-container-2">
                          <Input
                            placeholder="Enter duration"
                            disableUnderline={true}
                            name="projectDuration"
                            value={_get(values, 'projectDuration')}
                            required="required"
                            InputLabelProps={{ shrink: true }}
                            {...formProps}
                            onChange={(e) => {
                              setFieldValue('projectDuration', e.target.value);
                            }}
                            error={
                              _get(touched, 'projectDuration') &&
                              Boolean(_get(errors, 'projectDuration'))
                            }
                            variant="outlined"
                          />
                          <Select
                            sx={{
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }}
                            value={_get(values, 'durationUnit')}
                            onChange={(event) => {
                              setFieldValue('durationUnit', event.target.value);
                              setdurationLabel(getDurationLabel(event.target.value));
                            }}
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                            className="dd-duration"
                          >
                            <MenuItem
                              value="PERHOUR"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={hours}
                                style={{ height: 22, width: 22, marginRight: '20px' }}
                              />
                              Hours
                            </MenuItem>
                            <MenuItem
                              value="PERDAY"
                              sx={{ alignItems: 'center', display: 'flex', marginRight: '4px' }}
                            >
                              <img
                                src={days}
                                style={{
                                  height: 21,
                                  width: 21,
                                  marginRight: '22px',
                                  marginLeft: '-3px'
                                }}
                              />
                              Days
                            </MenuItem>
                            <MenuItem
                              value="PERWEEK"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={weeks}
                                style={{ height: 16, width: 16, marginRight: '20px' }}
                              />
                              Weeks{' '}
                            </MenuItem>
                            <MenuItem
                              value="PERMONTH"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={months}
                                style={{ height: 16, width: 16, marginRight: '20px' }}
                              />
                              Months
                            </MenuItem>
                          </Select>
                        </span>
                      </div>
                      <div className="error-msg-pro-duration">{errors.projectDuration}</div>
                    </div>

                    <div className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>Currency</InputLabel>
                          <Select
                            required
                            value={_get(values, 'currency')}
                            label="Currency"
                            onChange={(event) => {
                              setFieldValue('currency', event.target.value);
                              setCurrency(event.target.value);
                            }}
                          >
                            <MenuItem value={'R'}>R ZAR </MenuItem>
                            <MenuItem value={'$'}>$ Dollar</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>

                    <div className="step2-currency">
                      <FormTextField
                        name="budget"
                        label="Budget"
                        value={_get(values, 'budget')}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{currency}</InputAdornment>
                          )
                        }}
                        type="number"
                        {...formProps}
                        required
                      />
                    </div>

                    <div className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="hourlyRate"
                            label="Hourly rate"
                            value={_get(values, 'hourlyRate')}
                            onChange={(event) => {
                              let hRate = Number(event.target.value);
                              setFieldValue('hourlyRate', hRate);
                            }}
                            InputProps={{
                              inputComponent: numberFormatCustom,
                              startAdornment: (
                                <InputAdornment position="start">{currency}</InputAdornment>
                              ),
                              endAdornment: <InputAdornment position="start">/hr</InputAdornment>
                            }}
                          />
                        </FormControl>
                        <div
                          title="Hourly rate means the maximum hourly rate you are willing to pay for this work."
                          enterTouchDelay={0}
                        >
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </Box>
                    </div>

                    <div className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={
                            _get(touched, 'availabilityHoursPerWeek') &&
                            Boolean(_get(errors, 'availabilityHoursPerWeek'))
                          }
                          required
                        >
                          <InputLabel id="demo-simple-select-label">
                            Availability (per week)
                          </InputLabel>
                          <Select
                            value={_get(values, 'availabilityHoursPerWeek')}
                            label="Availability (per week)"
                            onChange={(event) => {
                              setFieldValue('availabilityHoursPerWeek', event.target.value);
                            }}
                            error={
                              touched.availabilityHoursPerWeek &&
                              Boolean(errors.availabilityHoursPerWeek)
                            }
                            helperText={
                              touched.availabilityHoursPerWeek && errors.availabilityHoursPerWeek
                            }
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value={10}>0-10 hours per week </MenuItem>
                            <MenuItem value={20}>10-20 hours per week</MenuItem>
                            <MenuItem value={30}>20-30 hours per week</MenuItem>
                            <MenuItem value={40}>30-40 hours per week</MenuItem>
                          </Select>
                        </FormControl>
                        <div
                          title=" Availability means how many hours per week the candidate should to be available to work on your project."
                          enterTouchDelay={0}
                        >
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </Box>
                    </div>

                    <div className="step2-currency">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Start date"
                          openTo="year"
                          disablePast
                          views={['year', 'month', 'day']}
                          required
                          name="startDate"
                          value={values.startDate}
                          onChange={(newValue) => {
                            setFieldValue('startDate', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onBlur={handleBlur}
                              {...params}
                              error={touched.startDate && Boolean(errors.startDate)}
                              helperText={touched.startDate && errors.startDate}
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    <div className="step2-currency">
                      <ButtonContainerStep2>
                        <div>
                          <Button
                            variant="outlined"
                            type="button"
                            className="step3-back-btn"
                            sx={{ width: '100%', height: '45px' }}
                            onClick={activeBackBtn}
                          >
                            Back
                          </Button>
                        </div>
                        <div>
                          <DraftBtnStep2
                            onClick={() => {
                              saveToDraft({ ...formProps, ...props });
                            }}
                          >
                            Save as Draft
                          </DraftBtnStep2>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            className="primary-Btn-step2"
                            type="submit"
                            sx={{ width: '100%', height: '45px' }}
                          >
                            NEXT
                          </Button>
                        </div>
                      </ButtonContainerStep2>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </PaddedBox>
    </>
    // {/* </div> */ }
  );
};

const mapStateToProps = (state) => {
  return {
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigStep: (details) => dispatch(setNewGigStep(details)),
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);
