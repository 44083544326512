import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Name } from '../header/header.styles';
import { ImageUI} from '../../components/Sidebar/Sidebar.styles';
import { CircularProgressbar } from 'react-circular-progressbar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import _findIndex from 'lodash/findIndex';
import MuiAppBar from '@mui/material/AppBar';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import _isEmpty from 'lodash/isEmpty';
import 'react-circular-progressbar/dist/styles.css';
import ResetPassword from '../../assests/ResetPassword.svg';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import _get from 'lodash/get';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import PeopleOutlineSharpIcon from '@mui/icons-material/PeopleOutlineSharp';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { getUserId, getUserType } from '../../utils/user';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { unRegisterChatServices } from '../../utils/chatUtils';
import { eraseCookie } from '../../utils/cookie';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getUserProfileRoute } from '../../routes/authcheck';
import MailIcon from '@mui/icons-material/Mail';
import { getUserDashboardRoute } from '../../routes/authcheck';
import Logos from '../../assests/logos.png';
import IcNotificationBell from '../../assests/ic-notification-bell.svg';
import IcNotificationBellSelected from '../../assests/ic-notification-bell-selected.svg';
import Individual from '../../assests/Individual.png';
import SubMenu from './SubMenu';
import { setNotification } from '../../store/actions/chat';
import VerifiedIcon from '../../assests/VerifiedTick.svg'
import ReactGA from "react-ga";
import { Zoom, Switch, FormGroup, FormControlLabel } from '@mui/material';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import './AuthLayout.scss';
import { toast } from 'react-toastify';
import { profilePrivateEngineer } from '../../services/gig';
ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);
const drawerWidth = 240;

const isMenuActive = (location, path, subMenuRoutes = []) => {
  if (location.pathname === path) {
    return true;
  } else if (!_isEmpty(subMenuRoutes)) {
    return (
      _findIndex(subMenuRoutes, (o) => {
        return location.pathname.search(o) !== -1;
      }) !== -1
    );
  }
  return false;
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const PersistentDrawerLeft = (props) => {

  let profileName = "";
  let displayPic = "";
  let userType = "";
  const user = getUserType();
  const userId = getUserId();
  const theme = useTheme();
  const [open, setOpen] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  TimeAgo.addDefaultLocale(en)
  const timeAgo = new TimeAgo()

  const [windowSize, setWindowSize] = useState({
    width: undefined,
  })

  const [checkedSwitch, setCheckedSwitch] = React.useState(false);

  const profilePrivacyMethod = async (e) => {
    setCheckedSwitch(e.target.checked);
    let data = {
      isPrivate: !e.target.checked,
      userId: userId
    };

    profilePrivateEngineer(data).then((e)=>{
      if (checkedSwitch === true) {
        toast.success('Your profile will be visible to businesses now.');
      }
      else if (checkedSwitch === false) {
        toast.success('Your profile will not be visible to businesses now.');
      }
    })
  };

  useEffect(() => {
    setCheckedSwitch(!props?.profile?.isPrivate);
  }, [props]);

  React.useEffect(() => {
    if (windowSize.width >= 768) {
      setOpen(true)
      props.setdrawerOpen(true);
    }
    else {
      setOpen(false)
      props.setdrawerOpen(false);
    }
  }, [windowSize.width]);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  {
    if (user === 'business') {
      profileName = _get(props, 'profile.basicInfo.name', 'Name Not updated');
      displayPic = _get(props, 'profile.companyDetails.profilePic', '');
      userType = { user };
    }
  }

  {
    if (user === 'engineer') {
      profileName = _get(props, 'profile.basicInfo.name', 'Name Not updated');
      displayPic = _get(props, 'profile.myProfile.profilePic', '');
      userType = { user };
    }
  }
  {
    if (user === 'admin') {
      profileName = _get(props, 'profile.basicInfo.name', 'Name Not updated');
      displayPic = _get(props, 'profile.basicInfo.profilePic', '');
      userType = { user };
    }
  }

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const signOutUser = () => {
    let sessionAccess = sessionStorage.getItem("access_token");
    let sessionRefresh = sessionStorage.getItem("refresh_token");
    if (!!sessionAccess) {
      window.close()
    }
    else {
      unRegisterChatServices();
      if (socket) {
        socket.disconnect();
      }
      setAnchorEl(null);
      handleMobileMenuClose();
      eraseCookie('refresh_token');
      eraseCookie('access_token');
      history.push('/signin');
    }
    setAnchorEl(null);
    handleMobileMenuClose();
    eraseCookie('refresh_token');
    eraseCookie('access_token');
    history.push('/');
  };

  const redirectOnClick = (path = '/') => {
    history.push(path);
  };

  const redirectqueryOnClick = () => {
    if (user === 'engineer') {
      history.push('/engineerquery');
    }
    if (user === 'business') {
      history.push('/bussinessquery');
    }
  };

  const redirectpasswordresetOnClick = () => {
    if (user === 'engineer') {
      history.push('/engineerpasswordreset');
    }
    if (user === 'business') {
      history.push('/bussinesspasswordreset');
    }
  };

  const menuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <List className="pro-menu">
        {user === 'engineer' || user === 'business' ? (
          <MenuItem
            onClick={() => {
              redirectOnClick(`${getUserProfileRoute(props.userType)}`);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <PeopleOutlineSharpIcon sx={{ width: '20px' }} />
            </ListItemIcon>
            <ListItemText primary={<Typography type="body2" sx={{ fontSize: '14px' }}>Profile</Typography>} />
          </MenuItem>
        ) : ''}

        {user === 'engineer' ?
          <FormGroup>
            <FormControlLabel
              title='Indicate whether your profile is visible to businesses or not.'
              control={<Switch checked={checkedSwitch} onChange={profilePrivacyMethod} size="small" title={checkedSwitch === true ? '' : ''} />}
              label="Profile Visibility" />
          </FormGroup>
          : ''}

        {user === 'engineer' || user === 'business' ? (
        <MenuItem onClick={() => {
          redirectqueryOnClick();
          handleMenuClose();
        }}>
          <ListItemIcon>
            <HelpOutlineIcon sx={{ width: '20px' }} />
          </ListItemIcon>
          <ListItemText primary={<Typography type="body2" sx={{ fontSize: '14px' }}>Raise a query</Typography>} />
        </MenuItem>
        )
        : ''}

        {user === 'engineer' || user === 'business' ? (
        <MenuItem onClick={() => {
          redirectpasswordresetOnClick();
          handleMenuClose();
        }}>
          <ListItemIcon sx={{ marginLeft: '2px' }}>
            <img src={ResetPassword} alt="resetpassword" fontSize="medium" width="40%" />
          </ListItemIcon>
          <ListItemText primary={<Typography type="body2" sx={{ fontSize: '14px' }}>Reset password</Typography>} />
        </MenuItem>
        )
        : ''}

        <MenuItem onClick={signOutUser}>
          <ListItemIcon>
            <LogoutOutlinedIcon sx={{ width: '20px' }} />
          </ListItemIcon>
          <ListItemText primary={<Typography type="body2" sx={{ fontSize: '14px' }}>Sign out</Typography>} />
        </MenuItem>
      </List>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const drawerWidth = 240;
  const themeInstance = {
    color: '#000'
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const his = useHistory();
  const history = useHistory();
  const location = useLocation();
  // const drawerWidth = 240;
  const percentage = _get(props, 'percentage', 0);

  // const redirectOnClick = (path = '/') => {
  //   history.push(path);
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setdrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setdrawerOpen(false);
  };

  const [latestNotifications, setlatestNotifications] = useState([])
  const [olderNotifications, setolderNotifications] = useState([])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setopenNotification(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
  }

  useEffect(() => {
    let oldNotifList = [];
    let latestNotifList = [];
    if (props.notifications.length >= 1) {
      for (let index = 0; index < props.notifications[0].length; index++) {
        let currentMsg = props.notifications[0][index];
        if (currentMsg.isRead == true) {
          oldNotifList.push(currentMsg);
        } else {
          latestNotifList.push(currentMsg)
        }
      }
    }
    setolderNotifications(oldNotifList);
    setlatestNotifications(latestNotifList);
  }, [props.notifications])

  const [openNotification, setopenNotification] = useState(false)
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const changeisRead = (id) => {
    socket.emit('postNotification', {
      "id": id,
      "isRead": true,
    });
    let notif = null;
    latestNotifications.map((cur, i) => { if (cur._id == id) { notif = cur } });
    notif.isRead = true;
    let allLatest = latestNotifications.filter((val, idx) => val._id !== id);
    setlatestNotifications(allLatest);
    setolderNotifications([notif, ...olderNotifications]);
  }

  const clearLatestNotifs = () => {
    if (latestNotifications.length > 0) {
      let notif = [];
      let tempNotif = null;
      latestNotifications.map((cur, idx) => { tempNotif = cur; tempNotif.isRead = true; notif.push(tempNotif); });
      setlatestNotifications([]);
      setolderNotifications([...notif, ...olderNotifications]);
    }
  }

  // const onGetNotifications = (data) => {
  //   props.setNotification(data);
  // }
  // useEffect(() => {
  //   socket.on('getNotification', onGetNotifications);
  // }, [])

  const getNotif = (userType, userId) => {
    if (userType === "business") {
      // console.log("bus")
      socket.emit('getNotification', { "businessId": userId, "userRole": [userType] }, (msg) => console.log(msg));
    }
    if (userType === "engineer") {
      // console.log("eng")
      socket.emit('getNotification', { "engineerId": userId, "userRole": [userType] }, (msg) => console.log(msg));
    }
    if (userType === "admin") {
      // console.log("ad")
      socket.emit('getNotification', { "userRole": ["admin"] }, (msg) => console.log(msg));
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ background: '#FFF' }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }), color: '#0140AA' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
              <div ref={wrapperRef}>
                <div className={`${openNotification ? 'bell-icon-open' : 'bell-icon-closed'}`}>
                  <img src={openNotification ? IcNotificationBellSelected : IcNotificationBell} alt='notification-icon' onClick={() => { setopenNotification(!openNotification); getNotif(user, userId); }} />
                  {(openNotification == false && latestNotifications.length > 0) ? <div class="number" /> : <></>}
                </div>
                {openNotification && <div className='notification-box'>
                  <div className='notifications-header'>
                    <text className='notification-txt'>Notifications</text>
                    <text className='mark-read-txt' onClick={() => { socket.emit('markAllNotification', { "userId": userId, "userRole": [user] }); clearLatestNotifs(); getNotif(user, userId); }}>Mark all as read</text>
                  </div>
                  <text className='all-txt'>All</text>
                  <div className='underline-style'>
                    <div className='blue-underline' />
                    <div className='grey-underline' />
                  </div>
                  <div className='notification-wrapper'>
                    {latestNotifications.length > 0 && <><div className='latest-old-txt'>LATEST</div>
                      {latestNotifications.map((val, i) =>
                        <div className='notification-item-container' onClick={() => {if(!!val.redirectUrl){his.push(val.redirectUrl)}}}>
                          {val.userRole.includes(getUserType()) && getUserType() == "engineer" ? <img src={!!val.engineerProfilePic?val.engineerProfilePic:Individual} className='name-initials' /> : val.userRole.includes(getUserType()) && getUserType() == "business" ? <img src={!!val.businessProfilePic?val.businessProfilePic:Individual} className='name-initials' /> : <img src={Individual} />}
                          <div className='notification-desciption'>
                            <div className='description-txt'>{val.notificationDescription}</div>
                            <div className='posted-ago'>{timeAgo.format(new Date(val.createdAt))}</div>
                          </div>
                          <div className='blue-dot-border' onClick={(e) => { if (e && e.stopPropagation) { e.stopPropagation(); }; changeisRead(val._id); getNotif(user, userId); }}><div className='blue-dot' /></div>
                        </div>
                      )}</>}
                    {olderNotifications.length > 0 && <><div className='latest-old-txt'>OLDER</div>
                      {olderNotifications.map((val, i) =>
                        <div className='notification-item-container' onClick={() => {if(!!val.redirectUrl){his.push(val.redirectUrl)}}}>
                          {val.userRole.includes(getUserType()) && getUserType() == "engineer" ? <img src={!!val.engineerProfilePic?val.engineerProfilePic:Individual} className='name-initials' /> : val.userRole.includes(getUserType()) && getUserType() == "business" ? <img src={!!val.businessProfilePic?val.businessProfilePic:Individual} className='name-initials' /> : <img src={Individual} />}
                          <div className='notification-desciption'>
                            <div className='description-txt'>{val.notificationDescription}</div>
                            <div className='posted-ago'>{timeAgo.format(new Date(val.createdAt))}</div>
                          </div>
                        </div>
                      )}</>}
                    {(latestNotifications.length == 0 && olderNotifications.length == 0) && <div>No notifications to show.</div>}
                  </div>
                </div>}
              </div>
              <IconButton title="Open Profile" onClick={handleProfileMenuOpen}>
                <Name sx={{ display: 'flex', alignItems: "center" }}>
                  {profileName}
                  {
                    props.userType == "engineer" && !!props?.profile?.isVerified && (props?.profile?.profileCompletionPercent == 100)
                    &&
                    <img className='verified-name-ico' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg" />
                  }
                </Name>
              </IconButton>
              <IconButton title="Open Profile" onClick={handleProfileMenuOpen} sx={{ p: 0 }}>
                {/* <Avatar alt={profileName} src={displayPic || DP} /> */}
                <Box sx={{ margin: 'auto' }}>
                  <Avatar
                    src={displayPic}
                    sx={{
                      width: 50,
                      height: 50,
                      objectFit: 'cover',
                      margin: 'auto',
                      border: '3px solid #eee',
                      boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                      backgroundColor: '#CCD8EE'
                    }}
                  >
                    {profileName[0]}
                  </Avatar>
                </Box>
              </IconButton>
              <Menu
                // sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {renderMobileMenu}
                {renderMenu}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={{
              mt: '10px',
              ml: '15px',
              cursor: 'pointer'
            }}
          >
            <img src={Logos} alt="GIG Engineer" onClick={() => { his.push(`/Homepage`); }} size="medium" width="100%" /></Box>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {props.menuList.map(({ text, icon, path, subMenuRoutes, subNav, notifications, iconClosed, iconOpened }, index) => (
            <SubMenu text={text} icon={icon} path={path} subMenuRoutes={subMenuRoutes} subNav={subNav} notifications={notifications} iconOpened={iconOpened} iconClosed={iconClosed} key={index} props={props} />
          ))}
        </List>
        <Divider />
        {props.menuType === 'engProfile' && (percentage < 60) && (
          <div title="Complete your profile to start searching for exciting gigs">
            <ImageUI title="Complete your profile to start searching for exciting gigs">
              <Box sx={{ 
                    overflow: 'auto',
                    marginTop: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
               }}>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  className="progress-bar"
                />
              </Box>
            </ImageUI>
          </div>
        )}

        {props.menuType === 'engProfile' && (percentage >= 60) && (
          <div title="Search for gigs & apply">
            <ImageUI>
              <Box sx={{
                overflow: 'auto',
                marginTop: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
              >
                <div className='persentage-icon'
                
                >
                  {`${percentage}%`}
                </div>
                <CircularProgressbar
                  value={percentage}
                  className="progress-bar"
                />
              </Box>
            </ImageUI>
          </div>
        )}

        {(props.menuType === 'engProfile' || props.menuType === 'ownProfile' || props.menuType === 'engineerquery' || props.menuType === 'bussinessquery' || props.menuType === 'engineerpasswordreset' || props.menuType === 'bussinesspasswordreset') && (
          <Button
            sx={{ fontWeight: '500', color: '#FF8008', fontSize: '12px', textDecoration: 'underline', display: 'flex', alignItems: 'center', gap: '2px', marginTop: '20px' }}
            onClick={() => {
              history.push(getUserDashboardRoute(props.userType));
            }}
          >
            <ArrowBackOutlinedIcon sx={{ fontSize: '14px' }} /> Go to {props.userType === 'business' ? 'Dashboard' : 'Dashboard'}
          </Button>
        )}
      </Drawer>
      {/* <Main open={open} sx={{ height: '100vh' }} >
        <DrawerHeader />
      </Main> */}
    </Box >
  );
}

const mapStateToProps = (state) => {
  return {
    chatNotifications: state.chat.chatNotifications,
    notifier: state.chat.notifier,
    profile: state.profile,
    auth: state.auth,
    myProfile: state.profile.myProfile || {},
    skillList: state.profile.skillList || [],
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    basicInfo: state.profile.basicInfo,
    notifications: state.chat.notifications,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setNotification: (details) => dispatch(setNotification(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersistentDrawerLeft);
