import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  Zoom,
  Box,
  Link
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import GridTableEngineer from '../../../components/GridTable/GridTableEngineer';
import { getUserType } from '../../../utils/user';
import { PaddedBox, EditedCard, PaddedFooter } from '../../app.styles';
import StarIcon from '@mui/icons-material/Star';
import { applyToGigApi } from '../../../services/engineerGig';
import { getFavorites, updateFavorite } from '../../../services/favorites';
import Pagination from '@mui/material/Pagination';
import { getGigLocation, scrollOnTop, formatDate, getDurationLabel } from '../../../utils/helpers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { connect } from 'react-redux';
import Footer from '../../../components/footer1/footer';
import logo from "../../../assests/TableView.svg"
import GridViewIcon from '@mui/icons-material/GridView';
import _toNumber from 'lodash/toNumber';
import "./Saved.scss";
import Hours from "../../../assests/Hours.svg";
import Days from "../../../assests/Days.svg";
import Weeks from "../../../assests/Weeks.svg";
import Months from "../../../assests/Months.svg";
import Skills from "../../../assests/Skills.png";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PageLoader from '../../../components/Loaders/PageLoader';

const getSkillsList = (skills) => {
  return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
};

const Saved = (props) => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const userType = getUserType();
  const filterApplied =
    !_isEmpty(props.searchKey) || !_isEmpty(props.filters) || !_isEmpty(props.sortBy);
  const [engineerProfile, setEngineerProfile] = useState([]);
  const [page, setPage] = useState(1);
  const [paginationObj, setPaginationObj] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [disableApply, setDisableApply] = useState(0);
  const [paginationVal, setPaginationVal] = useState(1);

  const history = useHistory();
  const [tableData, setTableData] = useState("tableView")
  const setListData = (data) => {
    setCurrentPage(_get(data, 'paginationResult.currentPage', 1));
    setPaginationObj(_get(data, 'paginationResult', {}));
    setList([..._get(data, 'gigList', [])]);
  };
  const getMessageLink = (id, obj) => {
    return (
      <div>
        {userType === 'engineer' && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              className="admin-all-btn"
              sx={{ color: "#FFFFFF", borderRadius: '5px', width: '127px', height: '32px', textTransform: 'capitalize' }}
              onClick={() => {

                history.push(`/saved/${_get(obj, '_id', '')}`, obj);
              }}
            >
              View Details
            </Button>
          </Box>
        )}

      </div>
    )
  }

  const setListDataEngineer = (data) => {
    const rowData = [];
    const engineerListData = _get(data, 'gigList', []);
    const totalRecords = _get(data, 'paginationResult', {});
    if (engineerListData && engineerListData.length > 0) {
      for (let obj of engineerListData) {

        const gigName = _get(obj, 'titleAndSkills.projectTitle', '') ? _get(obj, 'titleAndSkills.projectTitle', '') : '-';
        const postOn = formatDate(_get(obj, 'createdAt', '-') ? _get(obj, 'createdAt', '-') : '-');
        const gigId = _get(obj, 'applicationDetails.gigId', '');
        const location = _get(obj, 'deliverablesAndLocation.location.country', '-') ? _get(obj, 'deliverablesAndLocation.location.country', '-') : '-';
        const postBy = _get(obj, 'companyDetails.companyName', '') ? _get(obj, 'companyDetails.companyName', '') : '-';
        const userId = _get(obj, 'userId', '');
        const id = _get(obj, '_id', '');
        rowData.push([gigName, location, postOn, postBy, getMessageLink(id, obj), obj, gigId, userId]);

      }
    }
    setEngineerProfile(rowData);
    setPaginationVal(totalRecords.totalPages || 1);
  };

  const fetchMoreData = (pn) => {
    const obj = {
      paginationInput: {
        pageNumber: pn,
        pageSize: 12
      }
    };
    getFavorites(obj, setListData, setListDataEngineer);
  };

  useEffect(() => {
    if (tableData === 'cardView') {
      fetchMoreData(filterApplied ? 1 : paginationVal);
    } else {
      fetchMoreData(filterApplied ? 1 : page);
    }
  }, [props.searchKey, props.filters, props.sortBy]);


  const handleRClick = (obj, data) => {
    history.push(`/saved/${_get(obj[5], '_id', '')}`, obj[5]);
  };





  const applyToGig = async (id, index) => {
    setDisableApply(index + 1);
    const obj = {
      gigId: id
    };
    const gigApply = await applyToGigApi(obj);
    list[index] = gigApply;
    setList([...list]);
    setDisableApply(0);
  };

  const removeFromFavorites = (res, data) => {
    const currentRecords = paginationObj.totalRecords;
    const probableRecords = (paginationObj.currentPage) * paginationObj.pageSize;
    if (currentRecords === probableRecords) {
      fetchMoreData(currentPage);
    } else {
      fetchMoreData(currentPage);
    }
  };

  const updateFavoriteStatus = (job) => {
    const obj = {
      gigId: _get(job, '_id', ''),
      isFavourite: !_get(job, 'isFavourite', '')
    };
    updateFavorite(obj, removeFromFavorites);
  };

  const handleChange = (event, value) => {
    scrollOnTop();
    fetchMoreData(value);
    setCurrentPage(value);
  };

  useEffect(() => {
    fetchMoreData(currentPage);
  }, []);

  const onTableChange = (event, displayView) => {
    fetchMoreData(1);
    if (displayView === 'cardView') {
      setTableData('cardView');
      setPage(1);
    } else {
      setTableData('tableView');
      setPaginationVal(1);
    }
  };

  useEffect(() => {
    fetchMoreData(filterApplied ? 1 : page);
  }, [props.searchKey, props.filters, props.sortBy]);

  const headers = ['Gig Name', 'Location', 'Posted on', 'Posted By', 'Action'];

  return (
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }} >
        <div className='saved-gig-text'>
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{ fontWeight: 'Medium', textTransform: 'capitalize', fontSize: '22px' }}
          >
            Saved Gigs
          </Typography>
          {
            (tableData === 'tableView') ?
              <Box sx={{ cursor: 'pointer', marginTop: '11px' }}>
                <img sx={{ color: '#0000FF' }} src={logo} alt="logotable" fontSize="small"
                  onClick={(e) => onTableChange(e, 'cardView')}
                /></Box>
              :
              <GridViewIcon onClick={(e) => onTableChange(e, 'tableView')} sx={{ fontSize: '35px', color: '#818181', cursor: 'pointer', marginTop: '6px' }} />
          }
        </div>
       
        {(tableData === 'tableView') ?
          <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
             {_isEmpty(list) && (
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            className="no-record-text-table"
          >
            You don’t have any saved Gigs
          </Grid>
        )}
            {list.map((job, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <EditedCard sx={{ minHeight: '480px' }}>
                  <CardHeader
                    sx={{ position: "absolute", top: "3", right: "0", marginRight: '20px' }}
                    action={
                      <span title="Remove from saved gigs" arrow TransitionComponent={Zoom} enterTouchDelay={0}>
                        <IconButton
                          aria-label="favorites"
                          onClick={() => {
                            updateFavoriteStatus(job);
                          }}
                        >
                          <StarIcon sx={{ fontSize: '22px', color: '#f99600' }} />
                        </IconButton>
                      </span>
                    }
                  />
                  <Box sx={{ cursor: 'pointer', margin: 'auto' }}>
                    <Link onClick={() => {
                      history.push(`/saved/${_get(job, '_id', '')}`, job);
                    }}>
                      <Avatar
                        src={_get(job, 'companyDetails.logo', '')}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: 'cover',
                          margin: 'auto',
                          border: '3px solid #eee',
                          boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                          backgroundColor: '#CCD8EE'
                        }}
                      >
                        {_get(job, 'companyDetails.companyName[0]', '')}
                      </Avatar>
                    </Link>
                  </Box>
                  <div className='project-title-saved-gig' title="Project Title">
                    {_get(job, 'titleAndSkills.projectTitle', '') ? _get(job, 'titleAndSkills.projectTitle', '') : "-"}
                  </div>
                  <CardContent className="card-Content">
                    <Box sx={{ textAlign: 'center' }}>
                      <Link
                        onClick={() => {
                          history.push(`/saved/${_get(job, '_id', '')}`, job);
                        }}

                      >
                        <Typography variant="h6"
                          component="h6"
                          color="pColor.main"
                          sx={{ fontSize: '16px', marginTop: '-10px', cursor: "pointer", marginLeft: '10px' }}>More Details</Typography>
                      </Link>
                    </Box>
                    <List>
                      <ListItem disablePadding sx={{ cursor: 'default' }} title="Skills">
                        <ListItemIcon sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '5px' }} >
                          <span enterTouchDelay={0}>
                            <img sx={{ color: '#0000FF' }} src={Skills} alt="skills" width="19px" />
                          </span>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                overflow: 'hidden',
                              }}
                            >
                              {getSkillsList(_get(job, 'titleAndSkills.skillsRequired', []))}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem disablePadding sx={{ marginTop: '8px', cursor: 'default' }} title="Location">
                        <ListItemIcon sx={{ minWidth: '25px', marginLeft: '-1px', marginTop: '5px' }}>
                          <span
                            enterTouchDelay={0}
                          >
                            <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                          </span>
                        </ListItemIcon>
                        <ListItemText

                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                overflow: 'hidden',
                              }}
                            >
                              {getGigLocation(job.deliverablesAndLocation, job.isRemote)}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem disablePadding sx={{ marginTop: '8px', cursor: 'default' }} title="Duration">
                        <ListItemIcon sx={{ minWidth: '25px', marginLeft: '2px', marginTop: '5px' }}>
                          <span
                            enterTouchDelay={0}
                          >
                            {(_get(job, 'scopeAndBudget.durationUnit')) === "PERHOUR" ? <img sx={{ color: '#0000FF' }} src={Hours} alt="hours" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERDAY" ? <img sx={{ color: '#0000FF' }} src={Days} alt="days" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERWEEK" ? <img sx={{ color: '#0000FF' }} src={Weeks} alt="weeks" fontSize="medium" /> : (_get(job, 'scopeAndBudget.durationUnit')) === "PERMONTH" ? <img sx={{ color: '#0000FF' }} src={Months} alt="months" fontSize="medium" /> : <img sx={{ color: '#0000FF' }} src={Hours} alt="hours" fontSize="medium" />}
                          </span>

                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                              }}
                            >
                              {_get(job, 'scopeAndBudget.projectDuration', '-')} {getDurationLabel(_get(job, 'scopeAndBudget.durationUnit', 'Hours'))}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem disablePadding sx={{ marginTop: '8px', cursor: 'default' }} title="Hourly Rate">
                        <ListItemIcon sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '5px' }}>
                          <span enterTouchDelay={0}>
                            <AccessTimeIcon sx={{ fontSize: '20px', color: '#000' }} />
                          </span>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#5E5E5E',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              {_get(job, 'scopeAndBudget.hourlyRate')
                                ? ` ${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(job, 'scopeAndBudget.hourlyRate')}/hr `
                                : 'Not Disclosed'}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </EditedCard>
              </Grid>
            ))}
            {!_isEmpty(list) && (
              <Box sx={{ marginTop: '30px', width: '100%', marginLeft: '35px' }}>
                <Pagination
                  count={paginationObj.totalPages || 1}
                  shape="rounded"
                  className="pagination-root"
                  onChange={handleChange}
                  page={currentPage}
                  defaultPage={1}
                />
              </Box>
            )}
          </Grid>

          : <Grid container className="table-Padding" mt={2}>
            <GridTableEngineer
              headers={headers}
              rows={engineerProfile}
              handleChange={handleChange}
              handleRClick={handleRClick}
              pagination={paginationVal}
              defaultPage={_toNumber(paginationVal)}
              emptyMessage="You don’t have any saved Gigs"
            ></GridTableEngineer>
          </Grid>
        }

      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "200px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Saved);
