import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, PaddedFooter, EditedCard, NolongerComplete } from '../../app.styles';
import { red } from '@mui/material/colors';
import {
    Grid,
    CardContent,
    Avatar,
    Typography,
    
    Pagination,
    Box,
    Link,

} from '@mui/material';
import { getCompletedGigs } from '../../../services/engineerGig';
import { formatDate, getStringLocation, getGigLocation, getDurationLabel } from '../../../utils/helpers';
import { getUserId, getUserType } from '../../../utils/user';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import './Completegig.scss';
import GridTableEngineer from '../../../components/GridTable/GridTableEngineer';
import Footer from '../../../components/footer1/footer';
import logo from '../../../assests/TableView.svg';
import GridViewIcon from '@mui/icons-material/GridView';
import Hours from '../../../assests/Hours.svg';
import Days from '../../../assests/Days.svg';
import Weeks from '../../../assests/Weeks.svg';
import Months from '../../../assests/Months.svg';
import IcFeedbackGive from '../../../assests/ic-feedback-give.svg';
import IcFeedbackRecieve from '../../../assests/ic-feedback-recieve.svg';
import IcFeedbackView from '../../../assests/ic-feedback-view.svg';
import Skills from '../../../assests/Skills.png';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PageLoader from '../../../components/Loaders/PageLoader';

const CompletedGigs = (props) => {
  const [page, setPage] = useState(1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [completedGigs, setCompletedGigs] = useState([]);
  const history = useHistory();
  const [tableData, setTableData] = useState('tableView');
  const [list, setList] = useState([]); // acitve engineer list returned from api
  const [count, setCount] = useState(1); // count of total engineer
  const userType = getUserType();

  const getSkillsList = (skills) => {
    return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
  };

  const setListData = (data) => {
    setList(_get(data, 'gigList', []));
    setPage(_get(data, 'paginationResult.currentPage', 1));
    setCount(_get(data, 'paginationResult.totalPages', 1));
  };

  const setData = (data) => {
    const rowData = [];
    const completedListData = _get(data, 'gigList', []);
    const totalRecords = _get(data, 'paginationResult', {});
    if (completedListData && completedListData.length > 0) {
      for (let obj of completedListData) {
        const gigName = _get(obj, 'titleAndSkills.projectTitle', '');
        const gigId = _get(obj, 'applicationDetails.gigId', '');
        const userId = _get(obj, 'applicationDetails.userId', '');
        const location = getStringLocation(obj.deliverablesAndLocation, obj.isRemote);
        const startDate = _get(obj, 'scopeAndBudget.startDate', '')
          ? formatDate(_get(obj, 'scopeAndBudget.startDate', ''))
          : '-';
        const toDate = '-';
        const id = _get(obj, '_id', '');
        rowData.push([
          gigName,
          location,
          startDate,
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {toDate}
            {!!obj.referralsData ? (
              obj.referralsData.length > 1 ? (
                <img
                  title={'View submitted feedback'}
                  style={{ marginLeft: '16.59px', cursor: 'pointer' }}
                  src={IcFeedbackView}
                  alt="writeFeedback"
                  onClick={() =>
                    history.push(`/gig/review/engineer/viewSubmittedFeedback/${obj._id}`)
                  }
                />
              ) : obj.referralsData.length == 1 && obj.referralsData[0].giverId == getUserId() ? (
                <img
                  title={'View submitted feedback'}
                  style={{ marginLeft: '16.59px', cursor: 'pointer' }}
                  src={IcFeedbackView}
                  alt="writeFeedback"
                  onClick={() =>
                    history.push(`/gig/review/engineer/viewSubmittedFeedback/${obj._id}`)
                  }
                />
              ) : (
                <img
                  title={'Submit feedback'}
                  style={{ marginLeft: '16.59px', cursor: 'pointer' }}
                  src={IcFeedbackGive}
                  alt="writeFeedback"
                  onClick={() => history.push(`/gig/review/engineer/add/${gigId}`)}
                />
              )
            ) : (
              <img
                title={'Submit feedback'}
                style={{ marginLeft: '16.59px', cursor: 'pointer' }}
                src={IcFeedbackGive}
                alt="writeFeedback"
                onClick={() => history.push(`/gig/review/engineer/add/${gigId}`)}
              />
            )}
            {!!obj.referralsData ? (
              obj.referralsData.length > 1 ? (
                <img
                  title={'View received feedback'}
                  style={{ marginLeft: '15px', cursor: 'pointer' }}
                  src={IcFeedbackRecieve}
                  alt="viewRecievedFeedback"
                  onClick={() => history.push(`/gig/review/engineer/viewFeedback/${gigId}`)}
                />
              ) : obj.referralsData.length == 1 && obj.referralsData[0].giverId != getUserId() ? (
                <img
                  title={'View received feedback'}
                  style={{ marginLeft: '15px', cursor: 'pointer' }}
                  src={IcFeedbackRecieve}
                  alt="viewRecievedFeedback"
                  onClick={() => history.push(`/gig/review/engineer/viewFeedback/${gigId}`)}
                />
              ) : (
                <img
                  title={'View received feedback'}
                  style={{
                    marginLeft: '15px',
                    cursor: 'pointer',
                    pointerEvents: 'none',
                    opacity: 0
                  }}
                  src={IcFeedbackRecieve}
                  alt="viewRecievedFeedback"
                  onClick={() => history.push(`/gig/review/engineer/viewFeedback/${gigId}`)}
                />
              )
            ) : (
              <img
                title={'View received feedback'}
                style={{ marginLeft: '15px', cursor: 'pointer', pointerEvents: 'none', opacity: 0 }}
                src={IcFeedbackRecieve}
                alt="viewRecievedFeedback"
                onClick={() => history.push(`/gig/review/engineer/viewFeedback/${gigId}`)}
              />
            )}
            {/* <img title={"View received feedback"} style={{ marginLeft: "15px", cursor: "pointer" }} src={IcFeedbackRecieve} alt="viewRecievedFeedback" onClick={()=>history.push(`/gig/review/engineer/viewFeedback/${gigId}`)} /> */}
          </div>,
          { id, name },
          obj,
          gigId,
          userId
        ]);
      }
    }
    -setCompletedGigs(rowData);
    setPaginationVal(totalRecords.totalPages || 1);
  };

  const fetchData = (currentPage) => {
    const obj = {
      userId: getUserId(),
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 10
      },
      status: ['COMPLETED']
    };
    getCompletedGigs(obj, setData, setListData);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleCompleteClick = (data) => {
    const gigData = data.at(-2);
    history.push(`/complete/${gigData}`, data[5]);
  };

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const headers = ['GIG NAME', 'LOCATION', 'START DATE', 'END DATE'];

  const onTableChange = (event, displayView) => {
    fetchData(1);
    if (displayView === 'cardView') {
      setTableData('cardView');
      setPage(1);
    } else {
      setTableData('tableView');
      setPaginationVal(1);
    }
  };

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`,
          minHeight: '580px'
        }}
      >
        <div className="complete-gig-text">
          <Typography
            variant="h6"
            component="h6"
            color="pColor.main"
            sx={{
              fontWeight: 'Medium',
              textTransform: 'capitalize',
              fontSize: '22px',
              margin: '0 0 20px 0'
            }}
          >
            Completed Gigs
          </Typography>
          {tableData === 'tableView' ? (
            <GridViewIcon
              onClick={(e) => onTableChange(e, 'cardView')}
              sx={{ fontSize: '35px', color: '#818181', cursor: 'pointer', marginTop: '6px' }}
            />
          ) : (
            <Box sx={{ cursor: 'pointer', marginTop: '11px' }}>
              <img
                sx={{ color: '#0000FF' }}
                src={logo}
                alt="logotable"
                fontSize="small"
                onClick={(e) => onTableChange(e, 'tableView')}
              />
            </Box>
          )}
        </div>
        {tableData === 'tableView' ? (
          <Grid container className="table-Padding">
            <GridTableEngineer
              handleRClick={handleCompleteClick}
              headers={headers}
              rows={completedGigs}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(page)}
              emptyMessage="You don’t have any Completed Gigs"
            ></GridTableEngineer>
          </Grid>
        ) : (
          <>
            <Grid container item className="section-Margin" spacing={4} xs={12} md={12} lg={12}>
              {_isEmpty(list) && (
                <Grid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  className="no-record-text-table"
                >
                  You don’t have any Completed Gigs.
                </Grid>
              )}
              {list.map((job, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <EditedCard sx={{ minHeight: '450px' }}>
                    <Box sx={{ cursor: 'pointer', margin: 'auto' }}>
                      <Link
                        onClick={() => {
                          history.push(`/complete/${_get(job, '_id', '')}`, job);
                        }}
                      >
                        <Avatar
                          src={_get(job, 'companyDetails.logo', '')}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: 'cover',
                            margin: 'auto',
                            border: '3px solid #eee',
                            boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                            backgroundColor: '#CCD8EE',
                            marginTop: '0px'
                          }}
                        >
                          {_get(job, 'companyDetails.companyName[0]', '')}
                        </Avatar>
                      </Link>
                      <div
                        style={{
                          position: 'absolute',
                          top: '35px',
                          right: '35px',
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        <img
                          style={{ cursor: 'pointer' }}
                          src={IcFeedbackGive}
                          alt="writeFeedback"
                          onClick={() =>
                            history.push(`/gig/review/engineer/add/${_get(job, '_id', '')}`)
                          }
                        />
                        <img
                          style={{ marginLeft: '9px', cursor: 'pointer' }}
                          src={IcFeedbackRecieve}
                          alt="viewRecievedFeedback"
                          onClick={() =>
                            history.push(
                              `/gig/review/engineer/viewFeedback/${_get(job, '_id', '')}`
                            )
                          }
                        />
                      </div>
                    </Box>
                    <div className="project-title-complete-gig" title="Project Title">
                      {_get(job, 'titleAndSkills.projectTitle', '')
                        ? _get(job, 'titleAndSkills.projectTitle', '')
                        : '-'}
                    </div>
                    <CardContent className="card-Content">
                      <Box sx={{ cursor: 'pointer', textAlign: 'center' }}>
                        <Link
                          onClick={() => {
                            history.push(`/complete/${_get(job, '_id', '')}`, job);
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            color="pColor.main"
                            sx={{ fontSize: '16px', marginTop: '-10px', marginLeft: '10px' }}
                          >
                            More Details
                          </Typography>
                        </Link>
                      </Box>

                      <List>
                        <ListItem disablePadding sx={{ cursor: 'default' }} title="Skills">
                          <ListItemIcon
                            sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '5px' }}
                          >
                            <span enterTouchDelay={0}>
                              <img
                                sx={{ color: '#0000FF' }}
                                src={Skills}
                                alt="skills"
                                width="19px"
                              />
                            </span>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color: '#5E5E5E',
                                  overflow: 'hidden'
                                }}
                              >
                                {getSkillsList(_get(job, 'titleAndSkills.skillsRequired', []))}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem
                          disablePadding
                          sx={{ marginTop: '8px', cursor: 'default' }}
                          title="Location"
                        >
                          <ListItemIcon
                            sx={{ minWidth: '25px', marginLeft: '-1px', marginTop: '5px' }}
                          >
                            <span enterTouchDelay={0}>
                              <PlaceOutlinedIcon sx={{ fontSize: '23px', color: '#000' }} />
                            </span>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color: '#5E5E5E',
                                  overflow: 'hidden'
                                }}
                              >
                                {getGigLocation(job.deliverablesAndLocation, job.isRemote)}
                              </Typography>
                            }
                          />
                        </ListItem>

                        <ListItem
                          disablePadding
                          sx={{ marginTop: '8px', cursor: 'default' }}
                          title="Duration"
                        >
                          <ListItemIcon
                            sx={{ minWidth: '25px', marginLeft: '2px', marginTop: '5px' }}
                          >
                            <span enterTouchDelay={0}>
                              {_get(job, 'scopeAndBudget.durationUnit') === 'PERHOUR' ? (
                                <img
                                  sx={{ color: '#0000FF' }}
                                  src={Hours}
                                  alt="hours"
                                  fontSize="medium"
                                />
                              ) : _get(job, 'scopeAndBudget.durationUnit') === 'PERDAY' ? (
                                <img
                                  sx={{ color: '#0000FF' }}
                                  src={Days}
                                  alt="days"
                                  fontSize="medium"
                                />
                              ) : _get(job, 'scopeAndBudget.durationUnit') === 'PERWEEK' ? (
                                <img
                                  sx={{ color: '#0000FF' }}
                                  src={Weeks}
                                  alt="weeks"
                                  fontSize="medium"
                                />
                              ) : _get(job, 'scopeAndBudget.durationUnit') === 'PERMONTH' ? (
                                <img
                                  sx={{ color: '#0000FF' }}
                                  src={Months}
                                  alt="months"
                                  fontSize="medium"
                                />
                              ) : (
                                <img
                                  sx={{ color: '#0000FF' }}
                                  src={Hours}
                                  alt="hours"
                                  fontSize="medium"
                                />
                              )}
                            </span>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color: '#5E5E5E',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden'
                                }}
                              >
                                {_get(job, 'scopeAndBudget.projectDuration', '-')}{' '}
                                {getDurationLabel(
                                  _get(job, 'scopeAndBudget.durationUnit', 'Hours')
                                )}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem
                          disablePadding
                          sx={{ marginTop: '8px', cursor: 'default' }}
                          title="Rate"
                        >
                          <ListItemIcon
                            sx={{ minWidth: '25px', marginLeft: '1px', marginTop: '5px' }}
                          >
                            <span enterTouchDelay={0}>
                              <AccessTimeIcon sx={{ fontSize: '20px', color: '#000' }} />
                            </span>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color: '#5E5E5E',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden'
                                }}
                              >
                                {_get(job, 'scopeAndBudget.hourlyRate')
                                  ? ` ${_get(job, 'scopeAndBudget.currency', 'R')} ${_get(
                                      job,
                                      'scopeAndBudget.hourlyRate'
                                    )}/hr `
                                  : 'Not Disclosed'}
                              </Typography>
                            }
                          />
                        </ListItem>

                        {userType === 'engineer'
                          ? null
                          : _get(job, 'hireCount') === 0 && (
                              <ListItem
                                disablePadding
                                sx={{ marginTop: '8px', cursor: 'default' }}
                                title="Project Details"
                              >
                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '15px' }}>
                                  <span enterTouchDelay={0}>
                                    <DetailsIcon sx={{ fontSize: '18px', color: '#000' }} />
                                  </span>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        color: '#5E5E5E',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      {_get(job, 'titleAndSkills.projectDetails')
                                        ? ` ${_get(job, 'titleAndSkills.projectDetails')} `
                                        : 'Not Disclosed'}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            )}

                        {userType === 'engineer'
                          ? null
                          : _get(job, 'hireCount') === 0 && (
                              <ListItem
                                disablePadding
                                sx={{ marginTop: '8px', marginBottom: '-8px', cursor: 'default' }}
                                title="Project Title"
                              >
                                <ListItemIcon sx={{ minWidth: '25px', marginLeft: '15px' }}>
                                  <span enterTouchDelay={0}>
                                    <SubtitlesIcon sx={{ fontSize: '18px', color: '#000' }} />
                                  </span>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        color: '#5E5E5E',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      {_get(job, 'titleAndSkills.projectTitle')
                                        ? ` ${_get(job, 'titleAndSkills.projectTitle')} `
                                        : 'Not Disclosed'}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            )}
                      </List>

                    </CardContent>
                  </EditedCard>
                </Grid>
              ))}
            </Grid>
            <div>
              {!_isEmpty(list) && (
                <Box sx={{ marginTop: '30px' }}>
                  <Pagination
                    page={page}
                    count={count}
                    shape="rounded"
                    className="pagination-root"
                    onChange={handleChange}
                    defaultPage={1}
                  />
                </Box>
              )}
            </div>
          </>
        )}
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '200px'
              : '0px'
          }`
        }}
      >
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps)(CompletedGigs);
