import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import { Button, Dialog, Grid } from '@mui/material';
import { formatDate, getHireLocation, getQueryParams } from '../../../utils/helpers';
import { getCurrentGig, hireUnHire } from '../../../services/gig';
import Typography from '@mui/material/Box';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import Message from '../../../assests/message.svg';
import { toast } from 'react-toastify';
import GridPastCurrent from '../../../components/GridTable/GridPastCurrent';
import './CurrentPast.css';
import Footer from '../../../components/footer1/footer';
import PageLoader from '../../../components/Loaders/PageLoader';

const CurrentHires = (props) => {

  const query = getQueryParams();
  const queryPage = query.page;

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [currentState, setCurrentState] = useState({});
  const [currentEngProfilePic, setcurrentEngProfilePic] = useState(null)

  const getMessageLink = (msglink, engineerName, gigName, userId, gigId) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          // variant="contained"
          className="btn-unhire"
          onClick={() => {
            setOpen(true);
            setCurrentState({ engineerName, gigName, userId, gigId });
          }}
        >
          Unhire
        </Button>
        <br />
        <Box sx={{ marginLeft: '7px' }}>
          <a
            className="message-Link"
            onClick={() => {
              history.push(msglink);
            }}
          >
            <img src={Message} alt="message" fontSize="medium" width="100%" />
          </a>
        </Box>
      </Box>
    )
  }

  const unhireSubmit = async (e, gigName) => {
    e.preventDefault();
    let data = {
      gigId: currentState.gigId,
      userId: currentState.userId,
    }


    hireUnHire(data).then((e)=>{
      socket.emit('postNotification', {
        "notificationDescription": `You've been unhired for ${gigName}`,
        "userRole": ["engineer"],
        "engineerId": currentState.userId,
        "isRead": false,
        "engineerProfilePic": currentEngProfilePic,
        "redirectUrl": "/active",
      });
      toast.success(response.message);
      setOpen(false);
      fetchData(1);
    })
  }

  const currntHires = ['ENGINEER NAME', 'GIG NAME', 'LOCATION', 'START DATE', 'ACTION'];

  const setData = (data) => {
    const rowData = [];
    const hireList = _get(data, 'hiredetailsList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    if (hireList && hireList.length > 0) {
      for (let obj of hireList) {
        const engineerName = _get(obj, 'engineerName', '');
        const gigName = _get(obj, 'gigName', '');
        setcurrentEngProfilePic(_get(obj, 'userId.profileData.myProfile.profilePic', ''));
        const gigId = _get(obj, 'gigId._id', '');
        const userId = _get(obj, 'userId', '');
        let location = getHireLocation(_get(obj, 'location', ''));
        const fromDate = _get(obj, 'fromDate', '') ? formatDate(_get(obj, 'fromDate', '')) : '-';
        const message = `/ochat?to=${_get(obj, 'userId.profileData.basicInfo.userId', '')}`
        rowData.push([engineerName, gigName, location, fromDate, getMessageLink(message, engineerName, gigName, userId, gigId), gigId, userId, '', obj]);
      }
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: currentPage,
        pageSize: 12
      }
    };

    getCurrentGig(obj, setData);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const handleRowClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/hires/currenthires/${gigData?.gigId?._id}`);
  };

  const handleRClick = (data) => {
    history.push(`/engineer/details/${data[8]?.userId?.profileData?.basicInfo?.userId}`);
  };

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Grid container>
          <Typography
            variant="h6"
            component="h6"
            color="#0543AB"
            sx={{ fontWeight: '500px', textTransform: 'capitalize', fontSize: '22px', lineHeight: '33px', fontFamily: 'Poppins', fontWeight: 'normal', marginTop: '0px' }}
          >
            Current Hires
          </Typography>
          <Grid container sx={{ marginTop: '-34px' }}>
            <Grid container className="table-Padding">
              <GridPastCurrent
                headers={currntHires}
                rows={rows}
                handleRowClick={handleRowClick}
                handleRClick={handleRClick}
                handleChange={handleChange}
                pagination={paginationVal}
                defaultPage={_toNumber(page)}
                emptyMessage="You don't have any current hires yet."
              ></GridPastCurrent>
            </Grid>
          </Grid>
        </Grid>
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
          >
            <br />
            <Typography>
              Are you sure you want to unhire <strong>{currentState.engineerName}</strong> for <strong>{currentState.gigName}</strong>?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                marginTop: '30px',
              }}
            >
              <Button
                variant="contained"
                className="popup-process"
                sx={{
                  width: "140px",
                  backgroundColor: '#0140AA',
                }}
                onClick={(e) => unhireSubmit(e, currentState.gigName)}
              >
                Proceed
              </Button>
              <Box
                sx={{
                  marginLeft: '20px',
                }}                        >
                <Button
                  variant="contained"
                  className="popup-cancel"
                  type="submit" sx={{
                    width: '140px',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    newGig: state.newGig,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigDetails: (data) => dispatch(setNewGigDetails(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrentHires);
